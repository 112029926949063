<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-card :title="heading">

                    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                        <div class="alert-body">
                            {{error_message}}
                        </div>
                    </b-alert>
                
                    <b-form @submit.prevent="formSubmit">
                        <b-row>
                            <b-col md="12">

                                <div class="fmTable table-responsive" style="margin-bottom: 0px !important;">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="width: 48%">Misconduct (en) <span class="text-danger">*</span></th>
                                                <th scope="col" style="width: 48%">Misconduct (ch) <span class="text-danger">*</span></th>
                                                <th scope="col" style="width: 4%"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(fm, index) in form" :key="index">
                                                <td>
                                                    <b-form-input placeholder="3 to 500 characters" v-model="fm.misconduct_en" />
                                                </td>
                                                <td>
                                                    <b-form-input placeholder="3 到 500 个字符" v-model="fm.misconduct_ch" />
                                                </td>
                                                <td class="text-center">
                                                    <b-button variant="danger" @click="removeRow(index);">-</b-button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <!-- <b-row>
                                    <b-col md="11">
                                        <b-row>
                                            <b-col md="6">Misconduct (en)<span class="text-danger">*</span></b-col>
                                            <b-col md="6">Misconduct (ch)<span class="text-danger">*</span></b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>

                                <b-row v-for="(fm, index) in form" :key="index" class="mt-1">
                                    <b-col md="11">
                                        <b-row>
                                            <b-col md="6">
                                                <b-form-input size="lg" placeholder="3 to 500 characters" v-model="fm.misconduct_en" />
                                            </b-col>
                                            <b-col md="6">
                                                <b-form-input size="lg" placeholder="3 到 500 个字符" v-model="fm.misconduct_ch" />
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col md="1" class="text-center float-right">
                                        <b-button variant="danger" class="float-right under_construct_mobile_danger_btn" @click="removeRow(index);">-</b-button>
                                    </b-col>
                                </b-row> -->

                                <b-row>
                                    <b-col md="12" class="text-center">
                                        <hr>
                                        <b-button class="mt-1 under_construct_mobile_add_more_btn" variant="primary" @click="addRow">Add More</b-button>
                                    </b-col>
                                </b-row>
                                            
                                <b-row>
                                    <b-col>
                                        <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "warning" class = "mr-1">
                                            Submit
                                        </b-button>

                                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.push({ name:'misconduct' })">
                                            Cancel
                                        </b-button>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>

                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import {
        BFormTags, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import vSelect from 'vue-select';

    export default {
        components: {
            BFormTags, BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile,BBreadcrumb
        },

        directives: {
            Ripple,
        },

        data() {
            return {        
                error_message:null,
                showDismissibleAlert:false,

                form :[
                    {
                        misconduct_en : null,
                        misconduct_ch : null,
                    }
                ],

                // Page Labels
                heading : 'Add Misconduct',
                api     : '/api/add-misconduct',
            }
        },

        methods : {
            formSubmit(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        items : this.form,
                    },
                    api : this.api
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;

                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;

                        this.successAlert().then((result) => {
                            // this.$router.go(-1);
                            this.$router.push({ name:'misconduct' })
                        });
                    }
                });
            },

            addRow(){
                this.form.push({
                    misconduct_en : null,
                    misconduct_ch : null,
                })
            },

            removeRow(index) {
                if(this.form.length > 1){
                    this.form.splice(index, 1);
                }
            },

            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard'
              },{
                to:{name:'custom-forms'},
                text: 'Custom Forms'
              },{
                to:{name:'warning-letter'},
                text:'Warning Letter'
              },{
                to:{name:'misconduct'},
                text:'Misconduct'
              },{
                to:null,
                text:'Add Misconduct',
                active:true
              }];
              return item;
            }
        },
        mounted(){
        }
    }
</script>
